<template>
  <div v-if="dialog && user">
    <v-dialog
      :value="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="50%"
    >
      <v-card>
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="formModal">Withdraw</h5> -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <br />
              <div class="form-group">
                <label>Please enter the amount you want to Cash-out</label>
                <!-- <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-money-bill"></i>
                    </div>
                  </div> -->
                  <v-text-field
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    v-model="user.amount_d"
                    placeholder="Amount USD"
                    label="Amount USD"
                    :rules="rules.amount_d"
                    min="1"
                    type="number"
                  />
                  <!-- <input
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    class="form-control"
                    v-model="user.amount_b"
                    placeholder="Amount THAI"
                    :rules="rules.amount_b"
                    min="1"
                    type="number"
                  /> -->
                  <!-- <input
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    class="form-control"
                    v-model="user.amount_r"
                    placeholder="Amount KHMER"
                    :rules="rules.amount_r"
                    min="1"
                    type="number"
                  /> -->
                  <vuetify-money
                    class="mt-5"
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    v-model="user.amount_r"
                    :rules="rules.amount_r"
                    type="number"
                    label="Amount KH"
                  />
                  <v-text-field
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    v-model="user.amount_vn"
                    placeholder="Amount VN"
                    label="Amount VN"
                    :rules="rules.amount_vn"
                    min="1"
                    type="number"
                  />
                  <v-text-field
                    v-bind:options="options"
                    v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                    v-model="user.amount_rp"
                    placeholder="Amount RP"
                    label="Amount RP"
                    :rules="rules.amount_rp"
                    min="1"
                    type="number"
                  />
                  <!-- <input
                    v-else-if="
                      user.role_id != '612a3fcc1a1fcead8871e822' &&
                      user.currency_type == 2
                    "
                    class="form-control"
                    v-bind:options="options"
                    placeholder="Amount"
                    v-model="user.amount"
                    type="number"
                  /> -->
                  <vuetify-money
                    v-else-if="
                      user.role_id != '612a3fcc1a1fcead8871e822' &&
                      user.currency_type == 2
                    "
                    class="mt-5"
                    v-bind:options="options"
                    v-model="user.amount"
                    placeholder="Amount"
                    type="number"
                    label="Amount"
                  />
                  <v-text-field
                    v-else-if="
                      user.role_id != '612a3fcc1a1fcead8871e822' &&
                      user.currency_type == 4
                    "
                    v-bind:options="options"
                    placeholder="Amount"
                    label="Amount"
                    v-model="user.amount"
                    type="number"
                  />
                  <v-text-field
                    v-else-if="
                      user.role_id != '612a3fcc1a1fcead8871e822' &&
                      user.currency_type == 5
                    "
                    v-bind:options="options"
                    placeholder="Amount"
                    label="Amount"
                    v-model="user.amount"
                    type="number"
                  />
                  <v-text-field
                    v-else
                    v-model="user.amount"
                    placeholder="Amount"
                    label="Amount"
                    :rules="rules.amount"
                    min="1"
                    type="number"
                  />
                <!-- </div> -->
                <!-- <vuetify-money
                  v-bind:options="options"
                  v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                  class="form-control"
                  v-model="user.amount_d"
                  label="Amount USD"
                  :rules="rules.amount_d"
                  min="1"
                  type="number"
                />
                <vuetify-money
                  v-bind:options="options"
                  v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                  class="form-control"
                  v-model="user.amount_b"
                  label="Amount THAI"
                  :rules="rules.amount_b"
                  min="1"
                  type="number"
                />
                <vuetify-money
                  v-bind:options="options"
                  v-if="user.role_id == '612a3fcc1a1fcead8871e822'"
                  class="form-control"
                  v-model="user.amount_r"
                  label="Amount KHMER"
                  :rules="rules.amount_r"
                  min="1"
                  type="number"
                />
                <vuetify-money
                  v-else-if="
                    user.role_id != '612a3fcc1a1fcead8871e822' &&
                    user.currency_type == 2
                  "
                  class="form-control"
                  v-bind:options="options"
                  v-model="user.amount"
                  type="number"
                />
                <v-text-field
                  v-else
                  class="form-control"
                  v-model="user.amount"
                  label="Amount"
                  :rules="rules.amount"
                  min="1"
                  type="number"
                /> -->
              </div>

              <button
                type="button"
                class="btn btn-primary m-t-15 waves-effect"
                :disabled="loading"
                text
                @click="validate"
              >
                <i class="fas fa-money-bill"></i>
                Cash-out
              </button>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          amount: [(v) => !!v || "Input amount is required"],
          amount_b: [(v) => !!v || "Input amount is required"],
          amount_d: [(v) => !!v || "Input amount is required"],
          amount_r: [(v) => !!v || "Input amount is required"],
          amount_vn: [(v) => !!v || "Input amount is required"],
          amount_rp: [(v) => !!v || "Input amount is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: function () {
        return {
          user_id: null,
          amount: null,
          amount_r: null,
          amount_d: null,
          amount_b: null,
          amount_vn: null,
          amount_rp: null,
        };
      },
    },
  },
  data: () => {
    return {
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      optionsDollar: {
        locale: "integer",
        length: 21,
        precision: 2,
      },
    };
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if (this.user.role_id == "612a3fcc1a1fcead8871e822")
          this.user.amount = 0;
        else {
          this.user.amount_r = 0;
          this.user.amount_d = 0;
          this.user.amount_b = 0;
          this.user.amount_vn = 0;
          this.user.amount_rp = 0;
        }
        const data = {
          user_id: this.user._id,
          amount: parseFloat(this.user.amount),
          amount_r: parseInt(this.user.amount_r),
          amount_b: this.user.amount_b,
          amount_d: this.user.amount_d,
          amount_vn: this.user.amount_vn,
          amount_rp: this.user.amount_rp,
        };
        // console.log(data)
        this.$emit("submit", data);
        this.$emit("currentUser", this.user);
      }
    },
  },
};
</script>