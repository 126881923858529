<template>
  <div>
    <div class="deposit_layout">
      <span class="mr-1" style="color: green" v-if="item.role_id !='6191dc644825e6f57ffa231f' && item.role_id !='6191dce94825e65937fa2340'">
        <a
          @click="onDialogViewTransaction"
          :disabled="userp.role_id == '61bf5eb0c73d7eea8634fbf4' ? true : false"
        >
          <i class="fas fa-2x fa-file-invoice"></i>
        </a>
      </span>

      <span style="color: green" v-if="item.role_id !='6191dc644825e6f57ffa231f' && item.role_id !='6191dce94825e65937fa2340'">
        <a
          class="mr-1"
          @click="onDialogDeposit"
          :disabled="
            userp.role_id == '61bf5eb0c73d7eea8634fbf4' ||
            (userp && !userp.enable)
              ? true
              : false
          "
        >
          | Cash-in
        </a>
      </span>

      <span style="color: red" v-if="item.role_id !='6191dc644825e6f57ffa231f' && item.role_id !='6191dce94825e65937fa2340'">
        <a
          class="mr-1"
          @click="onDialogWithdraw"
          :disabled="
            userp.role_id == '61bf5eb0c73d7eea8634fbf4' ||
            (userp && !userp.enable)
              ? true
              : false
          "
        >
          Cash-out
        </a>
      </span>
    </div>

    <!----------------------------------------------------->

    <dialog-deposit
      :dialog-withdraw="dialogDeposit"
      :darkmode="darkmode"
      :dialog="dialogDeposit"
      :loading="loading"
      :user="item"
      @submit="onDeposit"
      @currentUser="updatedDeposit = $event"
      v-on:onCloseDialog="closeDialogDeposit"
    />
    <dialog-withdraw
      :dialog-withdraw="dialogWithdraw"
      :darkmode="darkmode"
      :dialog="dialogWithdraw"
      :loading="loading"
      :user="item"
      @submit="onWithdraw"
      @currentUser="updatedWithdaw = $event"
      v-on:onCloseDialog="closeDialogWithdraw"
    />
    <dialog-view-transaction
      v-if="dialogViewTransaction"
      :dialog-withdraw="dialogViewTransaction"
      :darkmode="darkmode"
      :dialog="dialogViewTransaction"
      :selectedUser="data.selectedUser"
      v-on:onCloseDialog="closeDialogViewTransaction"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogDeposit from "../DialogDeposit";
import DialogWithdraw from "../DialogWithdraw";
import DialogViewTransaction from "../DialogViewTransaction";

export default {
  components: { DialogWithdraw, DialogDeposit, DialogViewTransaction },
  props: ["darkmode", "item", "userp", "user"],
  data() {
    return {
      updatedDeposit: null,
      updatedWithdaw: null,
      data: {
        descending: true,
        sortBy: "_id",
        page: 1,
        rowsPerPage: 10,
        fields: ["user", "date", "amount", "old_balance", "new_balance"],
        search: "",
        selectedUser: null,
      },
      loading: false,
      dialogWithdraw: false,
      dialogDeposit: false,
      dialogViewTransaction: false,
    };
  },
  watch: {
    userp: function () {},
    user: function () {},
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    cancelDelete() {
      this.dialogDelete = false;
    },
    onDialogViewTransaction() {
      console.log('user',this.item);
      this.data.selectedUser = this.item._id;
      this.dialogViewTransaction = true;
    },
    closeDialogViewTransaction() {
      this.dialogViewTransaction = false;
    },
    onDialogDeposit() {
      this.dialogDeposit = true;
    },
    closeDialogDeposit() {
      (this.user = {
        user_id: null,
        amount: null,
      }),
        (this.dialogDeposit = false);
    },
    async onDeposit(request) {
      this.loading = true;
      if (this.item._id) {
        await this.deposit({ vm: this, data: request });
        await this.fetchUser();
        this.dialogDeposit = false;
        this.$emit("refresh");
      }
      this.loading = false;
    },
    onDialogWithdraw() {
      this.dialogWithdraw = true;
    },
    closeDialogWithdraw() {
      (this.user = {
        user_id: null,
        amount: null,
        amount_r: null,
        amount_b: null,
        amount_d: null,
        amount_vn: null,
        amount_rp: null,
      }),
        (this.dialogWithdraw = false);
    },
    async onWithdraw(request) {
      this.loading = true;
      if (this.item._id) {
        await this.withDraw({ vm: this, data: request });
        this.dialogWithdraw = false;
        await this.fetchUser();
        this.$emit("refresh");
      }
      this.loading = false;
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", [
      "fetchPopUp",
      "fetchCurrentUser",
      "deleteUser",
      "fetchListUsers",
      "changePassword",
      "withDraw",
      "deposit",
      "fetchListUsers",
      "updateUser",
    ]),
    ...mapActions("$_modules", ["fetchUser"]),
  },
};
</script>
